import React from 'react';

import Container from '@components/Container';
import FlexBox from '@components/layout/FlexBox';
import Wrapper from '@components/layout/Wrapper';
import Layout from '@mainComponents/Layout';
import settings from '@settings';
import PropTypes from 'prop-types';

const About = ({ pageContext: {uri} }) => (
	<Layout 
		headerType='page-without-image'
		showHeader
		path={uri}
		pageTitle='About Us'
		pageDescription='Here is some Information about the page'
		pageTopline='The peple behind this site'
	>
		<Wrapper>
			<Container>
				<FlexBox align='center' justify='center'>
					<div css={settings.mq({maxWidth: ['100%', '100%', '400px']})}>
						<h3>
							Who We are
						</h3>
						<p >
							We are Flawia and Theresa, two female photographers who have been working at Nordic Combined competitions for many years. Following the IOC`&apos`s devastating decision against accepting female NC athletes for the 2026 Olympics, we are now more determined than ever to get them there!
							Our goal is to give them a platform and to promote them with a professional online presence to attract sponsors and broaden their opportunities. We created this website where we are mainly sharing pictures and short news from the competitions.
							We aim to convey how undenialably strong, hard working and determined the women competing in NC are. <br/>
							If you are interested in supporting our project please don`&apos`t hesitate to contact us. 
							<a className='is-link' href="mailto:nocogirls@nocogirls.com">nocogirls@nocogirls.com</a> 
							or just via 
							<a
								className='is-link'
								target='_blank'
								rel='noreferrer'
								href="https://instagram.com/nocogirls?igshid=YmMyMTA2M2Y="
							>instagram</a>
						</p>
					</div>
				</FlexBox>
			</Container>
		</Wrapper>
	</Layout>
);

About.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default About;