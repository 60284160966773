/* eslint-disable react/prop-types */
import React from 'react';

import About from '@content/About';
import SiteHead from '@mainComponents/SiteHead';

const AboutPage = pageContext => <About pageContext={pageContext} />;

export const Head = ({location}) => <SiteHead location={location} />;;

export default AboutPage;